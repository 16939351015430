<template>
  <el-dialog
    :title="options.title ? options.title : '选择部门和人员'"
    :visible.sync="editShow"
    :close-on-click-modal="false"
    width="70%"
    append-to-body
    :before-close="handleCancel"
  >
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="deptId" label="部门">
        <template slot-scope="scope">
          <DeptCascaderBF
            style="width: 80%"
            :placeholder="'请选择部门'"
            :deptList="deptList"
            :deptIdArr="scope.row.deptIdArr"
            v-model="scope.row.deptId"
            :showAllLevels="true"
            :returnObj="true"
            @changeGetObj="(dept) => handleDeptChange(dept, scope.row)"
            @changeValueArr="
              (deptIdArr) => handleDeptArrChange(deptIdArr, scope.row)
            "
          />
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="用户">
        <template slot-scope="scope">
          <!-- <el-input v-model="scope.row.userId" placeholder="请输入用户"></el-input> -->
          <UserSelect
            style="width: 80%"
            :placeholder="'请选择用户'"
            v-model="scope.row.userIds"
            :queryParams="scope.row"
            :returnObj="true"
            :isMultiple="true"
            @changeGetObj="(user) => handleUserChange(user, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80" align="center">
        <template slot="header">
          <i
            style="color: green; font-size: 28px; cursor: pointer"
            class="el-icon-circle-plus-outline"
            @click="addRow"
          ></i>
        </template>
        <template slot-scope="scope">
          <el-button
            style="color: red"
            type="text"
            icon="el-icon-close"
            @click="removeRow(scope.$index)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button
        v-if="options.mode !== 'view'"
        type="primary"
        @click="handleSubmit"
        >确 认</el-button
      >
      <el-button @click="handleCancel">返 回</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "selectDeptAndUerDialog",
  components: {
    DeptCascaderBF: () => import("@/components/dept/DeptCascaderBF"),
    UserSelect: () => import("@/components/user/UserSelect"),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    deptList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    deptUserData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      editShow: false,
      tableData: [],
    };
  },
  watch: {
    isShow: {
      handler(val) {
        this.editShow = val;
        if (val) {
          // this.tableData = this.deptUserData.
          this.tableData = this.deptUserData.length ? this.deptUserData : [];
          //   this.getTableData();
        }
      },
    },
  },
  methods: {
    handleDeptChange(dept, row) {
      // 禁止选择相同的部门
      // let flag = false;
      // for(let i = 0; i < this.tableData.length - 1; i++) {
      //   if (this.tableData[i].deptId === dept.id) {
      //     flag = true;
      //     break;
      //   } 
      // }
      // if (flag) {
      //   this.$message.warning("不能选择相同的部门/组织");
      //   this.$set(this.tableData, this.tableData.length - 1, {
      //     userIds: [],
      //     deptId: "",
      //   });
      //   return; 
      // }
      row.userIds = [];
      row.userName = "";
      const index = this.tableData.indexOf(row);
      if (index !== -1) {
        const { id, ...deptData } = dept;
        // 更新tableData中的对应行
        this.$set(this.tableData, index, {
          ...row,
          ...deptData,
        });
      }
    },
    handleDeptArrChange(deptIdArr, row) {
      // row.deptIdArr = deptIdArr;
      row.deptIdArr = deptIdArr;
    },
    handleUserChange(users, row) {
      // row.users = users;
      row.userIds = users.map((item) => item.id);
      row.userName = users.map((item) => item.userName).join(",");

      //   const index = this.tableData.indexOf(row);
      //   if (index !== -1) {
      //     const { id, ...userData } = user;
      //     // 更新tableData中的对应行
      //     this.$set(this.tableData, index, {
      //       ...row,
      //       ...userData,
      //     });
      //   }
    },
    getTableData() {
      // 初始化时添加一行
      if (this.tableData.length === 0) {
        this.addRow();
      }
    },
    addRow() {
      this.tableData.push({
        deptId: "",
        userId: "",
      });
    },
    removeRow(index) {
      this.tableData.splice(index, 1);
    },
    getTableData() {},
    handleSubmit() {
      this.$emit("selectDeptAndUerList", this.tableData);
    },
    handleCancel() {
      this.$emit("closeSelectDeptAndUerDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
  max-height: 80vh;
  overflow: auto;
}

.dialog-footer {
  text-align: center;

  /deep/.el-button {
    margin: 0 20px;
  }
}
</style>
